<template>
  <div>
    <!-- Add maintenance -->
    <div v-if="!addOrUpdate">
      <div align="center" class="mt-3 mb-3">
        <button
          class="btn align-bottom button-add-maintenance"
          @click="addMaintenance"
        >{{ $t('maintenances.edit.add') }}</button>
      </div>
      <button v-if="!loadPage" class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("data_loading")}}
      </button>
      <table v-show="loadPage" class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col" class="resize-date">{{ $t('maintenances.edit.date') }}</th>
            <th scope="col">{{ $t('maintenances.edit.operation') }}</th>
            <th scope="col" class="resize-price">{{ $t('maintenances.edit.price') }}</th>
          </tr>
        </thead>
        <tbody v-for="item in maintenanceToPatch" v-bind:key="item.id">
          <tr>
            <td>
              {{formatDate(item.date, "date", "/")}}
              <br />
              <span
                v-if="item.hoursOnCounter!=null"
                v-b-tooltip.hover.top.html.window="$t('maintenances.edit.hours')"
                class="resize-hours"
              >{{item.hoursOnCounter}}h</span>
            </td>
            <td>
              {{item.maintenanceType_designation}}
              <br />
              <span
                class="resize-details"
                v-for="operation in item.operations"
                v-bind:key="operation.id"
              >{{operation.text}},</span>
            </td>
            <td>
              {{item.price}}
              <span v-if="item.price!=null">€</span>
              <br />
              <button class="btn align-right update-delete-buttons" @click="onDelete(item.id)">
                <img :src="deleteIcon" alt="icon delete action" />
              </button>
              <button
                class="btn align-right update-delete-buttons"
                @click="updateMaintenance(item)"
              >
                <img :src="updateIcon" alt="icon update action" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <MaintenanceEdit :item="this.itemToUpdate" @clicked="addOrUpdate = false" v-if="addOrUpdate" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import delIcon from "@/assets/images/delete_icon.svg";
import updIcon from "@/assets/images/update.svg";
import MaintenanceEdit from "@/components/machines/edit/MaintenanceEdit.vue";
import { askConfirm, formatDate } from "@/utils/commons";

export default {
  name: "maintenances",
  data() {
    return {
        loadPage: false,
        loadDetails: true,
        deleteIcon: delIcon,
        updateIcon: updIcon,
        itemToUpdate: {},
        addOrUpdate: false
    };
  },
  components: {
    MaintenanceEdit
  },
  created() {
    this.init();
  },
  methods: {
    init: async function() {
      // Load all maintenances of the current Machine
      this.loadPage = false;
      await this.$store.dispatch("maintenance/getAllMaintenancesByMachine", {machine : this.currentMachine.id});
      this.loadPage = true;
    },
    addMaintenance() {
      this.itemToUpdate = {};
      this.addOrUpdate = !this.addOrUpdate;
    },
    updateMaintenance(item) {
      this.itemToUpdate = item;
      this.addOrUpdate = !this.addOrUpdate
    },
    async onDelete(maintenanceId) {
      if(await askConfirm(this.$t("confirm_delete")))
      {
        await this.$store.dispatch("maintenance/deleteMaintenance", {id : maintenanceId});
        this.init();
      }
    },
    formatDate,
  },
  computed: {
    ...mapGetters({ currentMachine: "machine/currentEntry", maintenances: "maintenance/maintenancesByMachine" }),
    maintenanceToPatch() {
      let maintenanceClone = JSON.parse(JSON.stringify(this.maintenances));
      maintenanceClone.sort(function(a, b) {
        var c = new Date(a.date);
        var d = new Date(b.date);
        return d-c;
      });
      return maintenanceClone;
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Params button maintenance machine.
 */
.button-add-maintenance {
  background-color: $background-text-color;
  width: 175px;
}

.button-add-maintenance:hover {
  background: $secondary-light-color;
}

.button-add-repair {
  background-color: $background-text-color;
  width: 190px;
  padding: 2px 2px 2px 2px;
}

.button-add-repair:hover {
  background: $secondary-light-color;
}

table > thead > tr,
th {
  font-size: 0.9em;
}

table > tbody > tr,
td {
  font-size: 0.87em;
  padding-bottom: 0rem !important;
}

table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.resize-date {
  width: 26.9%;
}

.resize-price {
  width: 26%;
}

.resize-details {
  font-size: 0.8em;
}

.resize-hours {
  font-size: 0.92em;
}
</style>
