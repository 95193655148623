<template>
  <div>
    <h4 class="subtitle-form overload-subtitle mb-2">{{$t('maintenances.edit.interview_type')}}</h4>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select"
      label="designation"
      :options="maintenanceForm"
      :value="this.selectedMaintenanceType"
      @input="selectedInput => selectedMaintenanceType=selectedInput"
    />
    <b-row class="mt-2">
      <b-col cols="6">
        <h4 class="subtitle-form overload-subtitle mb-2">{{$t('maintenances.edit.interview_date')}}</h4>
      </b-col>
      <b-col cols="6">
        <h4 class="subtitle-form overload-subtitle mb-2">{{$t('maintenances.edit.hours')}}</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <input type="date" ref="maintenanceDate" id="example-datepicker" v-model="maintenanceDate" placeholder="yyyy-mm-dd" required/>
      </b-col>
      <b-col cols="6">
        <NumericInput
                inputRef="input"
                :value="counterHours"
                @input="i => {counterHours = i}"
                :numberOfDigits="2"
          />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <h4 class="subtitle-form overload-subtitle mb-2">{{$t('maintenances.edit.price')}}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pr-0 col-5">
        <NumericInput
                inputRef="input"
                :value="price"
                @input="i => {price = i}"
                :numberOfDigits="2"
          />
      </b-col>
      <b-col class="pr-0 col-7">
        <span>€</span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <h4 class="subtitle-form overload-subtitle mb-2">{{$t('maintenances.edit.operations')}}</h4>
      </b-col>
    </b-row>
    <b-row
      class="row-resize mb-1"
      v-for="operation in selectedOperations"
      v-bind:key="operation.text"
    >
      <b-col cols="10" class="text-infos bg-white">{{operation.text}}</b-col>
      <b-col cols="2" class="remove-btn">
        <button class="btn align-bottom" @click="onDeleteOperation(operation)">
          <img :src="icon" alt="icon delete action" />
        </button>
      </b-col>
    </b-row>

    <Dropdown
      ref="dropdown"
      class="shape-custom-select"
      label="label"
      :options="operationsToPatch"
      @input="selectedInput => addOperation(selectedInput)"
    />
    <b-row class="mt-2" v-if="this.OtherOperationBoolean">
      <b-col cols="10">
        <b-input
          type="text"
          placeholder="Renseigner l'opération ici"
          id="example-datepicker"
          v-model="otherOperation"
          size="sm"
        />
      </b-col>
      <b-col cols="2" class="remove-btn">
        <button class="btn align-bottom" @click="addOtherOperation()">
          <img :src="addIcon" alt="icon add action" />
        </button>
      </b-col>
    </b-row>
    <div align="center" class="mb-3 mt-4">
      <button class="btn align-bottom button-add-maintenance" @click="onSave" type="submit">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/machines/details/Dropdown.vue";
import delIcon from "@/assets/images/delete.svg";
import addIcon from "@/assets/images/plus.svg";
import {checkDatePattern, checkTimePattern} from "@/utils/commons";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "maintenance-edit",
  created() {
    this.init()
  },
  data() {
    return {
        maintenanceDate: null,
        selectedMaintenanceType: {},
        selectedMaintenanceTypeClone: {},
        selectedOperations: [],
        counterHours: null,
        price: null,
        icon: delIcon,
        addIcon: addIcon,
        OtherOperationBoolean: false,
        otherOperation: '',
        add: true,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
      Dropdown,
      NumericInput
  },
  methods: {
    async init() {
      await this.$store.dispatch("machine/getMaintenanceForm", {id : this.currentMachine.id});
      if(Object.keys(this.item).length != 0) 
      {
        this.maintenanceDate = this.item.date;
        this.price = this.item.price;
        this.counterHours = this.item.hoursOnCounter;
        
        if(this.item.maintenanceType != null) this.selectedMaintenanceType = this.maintenanceForm.filter(element => element.designation == this.item.maintenanceType_designation)[0];
        
        this.item.operations.map(element => this.selectedOperations.push({text : element.text})) 
        this.add = false;
      } 
    },
    async onSave() { 
      if(!this.maintenanceDate || !checkDatePattern(this.maintenanceDate))
        return;
      this.addOtherOperation();
      let dataToPatch = {
            machine: this.currentMachine.id,
            maintenanceType_designation: Object.keys(this.selectedMaintenanceType).length != 0 ? this.selectedMaintenanceType.designation : '',
            maintenanceType: Object.keys(this.selectedMaintenanceType).length != 0 ? this.selectedMaintenanceType.id : '',
            date: this.maintenanceDate,
            updatedAt: new Date().toISOString(),
            hoursOnCounter: this.counterHours == null || this.counterHours === "" ? null: this.counterHours,
            price: this.price == null || this.price === "" ? null: this.price,
            operations: this.selectedOperations
          }
      if(this.add == true) {
          await this.$store.dispatch("maintenance/createMaintenance", {
          data: dataToPatch,
          });
      }  
      else {
          await this.$store.dispatch("maintenance/updateMaintenance", {id: this.item.id,
          data: dataToPatch,
          });
      }
      await this.$store.dispatch("maintenance/getAllMaintenancesByMachine", {machine : this.currentMachine.id});
      this.$emit('clicked', '')
    },
    onDeleteOperation(operation) {
      this.selectedOperations = this.selectedOperations.filter(function(value, index, arr){ 
        return value.text != operation.text;
    });
    },
    addOperation(value) {
      if(value.label != "Autre opération") {
        this.selectedOperations.push({text: value.label});
        this.OtherOperationBoolean = false;
      }
      else this.OtherOperationBoolean = true;
    },
    addOtherOperation() {
      if (this.otherOperation != '') {
        this.selectedOperations.push({text : this.otherOperation})
        this.otherOperation = '';
      }
    }
  },
  computed: {
    ...mapGetters({
      currentMachine: "machine/currentEntry",
      maintenanceForm: "machine/maintenanceForm",
    }),
    operationsToPatch() {
      if(Object.keys(this.selectedMaintenanceType).length != 0)
      {
        this.selectedMaintenanceTypeClone = JSON.parse(JSON.stringify(this.selectedMaintenanceType.operations));
        this.selectedMaintenanceTypeClone.push({label : "Autre opération"})
        return this.selectedMaintenanceTypeClone;
      }
      return [];
    }
  },
  watch: {
    maintenanceDate: {
      handler: async function() {
        if(!checkDatePattern(this.maintenanceDate)) {
          this.$refs.maintenanceDate.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        }else{
          this.$refs.maintenanceDate.setCustomValidity("");
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
/**
 * Date params.
 */
.inputs-date {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

.button-add-maintenance {
  background-color: $secondary-light-color;
  width: 175px;
}

button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

/**
 * Resize rows.
 */
.row-resize {
  padding-left: 16px;
}
</style>
